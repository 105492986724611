import {validateDateOfBirthField} from "../healthquotes-validation.js";

/** Initialize date of birth (dob) fields. */
export function initializeDateOfBirthFields() {
    $("input[name=dob]").each(function () {
        var dobField = $(this);
        var placeholder = dobField.prop("placeholder") || "mm/dd/yyyy";
        dobField
            .data("val-required", true)
            .mask("99/99/9999")
            .prop("pattern", "^(0?[1-9]|1[0-2])/(0?[1-9]|[1-2][0-9]|30|31)/(19|20)[0-9]{2}$")
            .prop("maxlength", "10")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your date of birth")
            .prop("autocomplete", "bday");

        const id = dobField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": "dob-error",
                class: "invalid-feedback text-end",
                html: "Please enter a valid date of birth",
            }).insertAfter(dobField);
        }

        dobField.on("change", function () {
            $("input[name=insuranceType],select[name=insuranceType]").each(function () {
                const insuranceType = $(this);
                const age = validateDateOfBirthField(dobField, errorElement);
                let insuranceTypeVal = insuranceType.val() || "Health Insurance";

                if (age > 0) {
                    const isMedicare = age >= 64.5;
                    sessionStorage.setItem("isMedicare", isMedicare ? "true" : "false");
                    if (isMedicare && "Medicare Supplement" !== insuranceTypeVal && "Medicare Advantage" !== insuranceTypeVal) {
                        insuranceTypeVal = "Medicare Supplement";
                    } else if (!isMedicare && "Health Insurance" !== insuranceTypeVal && "Short Term Health Plan" !== insuranceTypeVal) {
                        insuranceTypeVal = "Health Insurance";
                    }
                }
                insuranceType.val(insuranceTypeVal).trigger("change");
            });
        });

        dobField.on("keypress", function () {
            dobField.removeClass("is-valid").removeClass("is-invalid");
        });

        if (dobField.val()) {
            dobField.trigger("change");
        }
    });
}
