import {handleValidation, validateField} from "../form.js";
import {validateDateOfBirthFields} from "../healthquotes-validation.js";

const dob = $("#dob");
const dobDay = $("#dob-day");
const dobMonth = $("#dob-month");
const dobYear = $("#dob-year");
const dobError = $("#dob-error");

/** Initialize date of birth (dob) selectors. */
export function initializeDateOfBirthSelectors() {
    dobDay.on("change", function () {
        handleValidation(dobDay, dobError, validateField(dobDay));
        validateDateOfBirthFields(dobYear, dobMonth, dobDay, dob, dobError);
    });

    dobMonth.on("change", function () {
        handleValidation(dobMonth, dobError, validateField(dobMonth));
        validateDateOfBirthFields(dobYear, dobMonth, dobDay, dob, dobError);
    });

    dobYear.on("change", function () {
        handleValidation(dobYear, dobError, dobYear.val() && dobYear.val() > 1800 && dobYear.val() <= new Date().getFullYear());
        validateDateOfBirthFields(dobYear, dobMonth, dobDay, dob, dobError);
    });
}
