import {handleValidation} from "../form.js";
import "../../third-party/google-places.js";

/** Initialize street address fields. */
export function initializeStreetAddressFields() {
    $("input[name=streetAddress]").each(function () {
        const streetAddressField = $(this);
        const placeholder = streetAddressField.prop("placeholder") || "123 Main St";
        streetAddressField
            .prop("pattern", ".*\\d{1,}.* .*[a-zA-Z]{1,}.*|.*[a-zA-Z]{1,}.* .*\\d{1,}.*")
            .data("val-required", true)
            .prop("minlength", "2")
            .prop("maxlength", "50")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your home address")
            .prop("autocomplete", "address-line1");

        const id = streetAddressField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": errorElementId,
                class: "invalid-feedback text-end",
                html: "Please enter a valid street address",
            }).insertAfter(streetAddressField);
        }

        streetAddressField.on("change", function () {
            verifyStreetAddress(streetAddressField, errorElement);
        });
    });
}

export function verifyStreetAddress(streetAddressField, streetAddressError) {
    return handleValidation(streetAddressField, streetAddressError);
}
