import {handleValidation, validateField} from "../forms/form.js";

/**
 * @typedef {object} Autocomplete
 * @property {function} getPlace returns the {@link Place} object associated with the Autocomplete object
 * @property {function} addListener adds an event listener to the Autocomplete object
 */

/**
 * @typedef {object} Place
 * @property {AddressComponent[]} address_components the address components returned by Google Places API
 */

/**
 * @typedef {object} AddressComponent
 * @property {string[]} types the component type(s) - only the first value is used
 * @property {string} long_name the long name of an address component
 * @property {string} short_name the short name/value of an address component
 */

/**
 * @type {Autocomplete}
 */
let autocomplete;

/* Creates and appends a script tag for Google Places API with async and defer attributes. */
const googlePlacesScript = document.createElement("script");
googlePlacesScript.type = "text/javascript";
googlePlacesScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAMT3aHVrKsh_l1Ip4U4VKQNwR94Ujs1QQ&libraries=places&callback=initAutocomplete";
googlePlacesScript.async = true;
googlePlacesScript.defer = true;
googlePlacesScript.loading = "async";
document.body.appendChild(googlePlacesScript);

/** Initializes Google Places Autocomplete on address input fields. */
export function initAutocomplete() {
    const addresses = $("input[name=streetAddress]");

    addresses.each(function () {
        /* Initializes the Autocomplete object for the current input element. */
        // noinspection JSUnresolvedReference
        autocomplete = new google.maps.places.Autocomplete(
            this,
            {
                componentRestrictions: {country: ["us"]},
                fields: ["address_components"],
                types: ["address"],
            },
        );
        autocomplete.addListener("place_changed", () => fillInAddress(this));
    });
}

window.initAutocomplete = initAutocomplete;

/**
 * Fill in the address fields based on the selected place.
 *
 * @param {HTMLInputElement} inputElement the input element for the address
 */
export function fillInAddress(inputElement) {
    const address = $(inputElement);
    const zipcode = $("#zipcode");

    /* The place found by Google Places API. */
    const place = autocomplete.getPlace();
    parseAddress(address.val());

    /* Street number and route initialization. */
    let streetNumber = "";
    let route = "";

    for (const component of place.address_components) {
        const componentType = component.types[0];
        let value = component.short_name;

        switch (componentType) {
            case "street_number":
                setHiddenField("streetNumber", value);
                streetNumber = value;
                break;
            case "route":
                setHiddenField("route", value);
                route = value;
                break;
            case "locality":
                setHiddenField("city", value);
                break;
            case "sublocality":
                if (!getHiddenFieldValue("city")) {
                    setHiddenField("city", value);
                }
                break;
            case "administrative_area_level_1":
                setHiddenField("state", component.long_name);
                setHiddenField("stateAbbreviation", value);
                break;
            case "administrative_area_level_2":
                setHiddenField("county", value);
                break;
            case "postal_code":
                setHiddenField("zipcode", value);
                zipcode.val(value);
                zipcode.trigger("change");
                break;
            case "country":
                setHiddenField("country", value);
                break;
        }
    }

    /* Fallback for setting the city field if not already set. */
    if (!getHiddenFieldValue("city")) {
        ["administrative_area_level_2", "neighborhood", "political", "sublocality_level_1"].forEach(
            function (componentType) {
                for (const component of place.address_components) {
                    if (componentType === component.types[0]) {
                        setHiddenField("city", component.short_name);
                    }
                }
            },
        );
    }

    /* Constructs and sets the full address. */
    let address_selected = `${streetNumber} ${route}`.trim();
    if (address_selected) {
        address.val(address_selected);
        address.change();
        setHiddenField("streetAddress", address_selected);
        setHiddenField("verifiedAddress", "true");
        setHiddenField("verifiedZipcode", "true");

        /* Update customer with all the form fields. */
        const data = $("form").serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});
        const csrfToken = document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content");
        $.post({
            url: "/api/session-data/patch",
            contentType: "application/json",
            data: JSON.stringify(data),
            headers: {
                "X-CSRF-TOKEN": csrfToken,
            },
        });
    }
}

/**
 * Parse a comma-separated Google Places address line into street number, route, city, state, and country.
 *
 * Google Places doesn't always return all the address components, so this method
 * attempts to populate components beforehand. Any components returned will
 * replace these values.
 *
 * @param {string} address the address line
 */
export function parseAddress(address) {
    if (address) {
        let addressParts = address.split(",");
        if (addressParts.length) {
            let route = addressParts.shift();
            let streetNumber = route.match(/\d+/)?.[0];
            route = route.replace(streetNumber, "").trim();
            if (streetNumber) {
                setHiddenField("streetNumber", streetNumber);
            }
            if (route) {
                setHiddenField("route", route);
            }
        }
        if (addressParts.length) {
            let city = String(addressParts.shift()).trim();
            if (city) {
                setHiddenField("city", city);
            }
        }
        if (addressParts.length) {
            let state = String(addressParts.shift()).trim();
            if (state) {
                setHiddenField("stateAbbreviation", state);
            }
        }
        if (addressParts.length) {
            let country = String(addressParts.shift()).trim();
            if (country) {
                setHiddenField("country", country);
            }
        }
    }
}

/**
 * Set the value of a hidden input field. Create the field if it doesn't exist.
 *
 * @param {string} name the name of the hidden input field
 * @param {string} value the value to set
 */
function setHiddenField(name, value) {
    let field = $(`input[name="${name}"]`);
    if (field.length === 0) {
        field = $("<input>", {
            type: "hidden",
            name: name,
            value: value,
        });
        $("form").append(field);
    } else {
        field.val(value);
    }
}

/**
 * Get the value of a hidden input field.
 *
 * @param {string} name the name of the hidden input field
 * @returns {string} the value of the hidden input field
 */
function getHiddenFieldValue(name) {
    return $(`input[name="${name}"]`).val();
}

/* Sets the autocomplete property for streetAddress input after a delay. */
setTimeout(() => {
    $("#streetAddress").prop("autocomplete", "address-line1");
}, 2000);

/**
 * Handles Google Maps API authentication failure by replacing the address field.
 *
 * When authentication fails, the default behavior is to
 * keep the field locked. In order to allow the customer
 * to continue, we need to completely replace the field
 * because Google binds so many events it is almost
 * impossible to remove them all.
 */
export function gm_authFailure() {
    const address_old = $("#streetAddress");
    const address = address_old
        .clone()
        .removeAttr("disabled")
        .css("background-image", "none")
        .prop("placeholder", "123 Main St")
        .prop("class", "form-control street-address")
        .insertAfter(address_old);

    /* Unbind all events from the autocomplete object. */
    // noinspection JSUnresolvedReference
    autocomplete.unbindAll();
    address_old.remove();

    /* This places the cursor at the end of the existing text. */
    address.focus();
    const address_value = address.val();
    address.val("");
    address.val(address_value);

    /* Hide the address error message. */
    const addressError = $("#street-address-error");
    addressError.hide();

    /* Attach change and blur event handlers to the new address input. */
    address.on("change blur", function () {
        handleValidation(address, addressError, validateField(address));
    });
}

window.gm_authFailure = gm_authFailure;
